let burger = document.querySelector('.js-btn')
let menu = document.querySelector('.js-menu')
let body = document.querySelector('body')
let close = document.querySelector('.js-btn--close')
let overlay = document.querySelector('.js-overlay')

if(burger){
  burger.addEventListener('click', () => {
    menu.classList.toggle('is-active');
    body.classList.toggle('is-overflow')
    overlay.classList.toggle('is-active')
  });
}

if(close){
  close.addEventListener('click', () => {
    menu.classList.toggle('is-active');
    body.classList.toggle('is-overflow')
    overlay.classList.toggle('is-active')
  });
}

if(overlay){
  overlay.addEventListener('click', () => {
    overlay.classList.toggle('is-active')
    menu.classList.toggle('is-active');
    body.classList.toggle('is-overflow')
  });
}


